import React, { useState, useEffect } from 'react'
import "./contacts.css"
import { DataGrid} from '@mui/x-data-grid';

import Input from '../ReUsedComponents/inputField/Input'
import SubmitButton from '../ReUsedComponents/submitButton/SubmitButton';
import Loading from '../ReUsedComponents/loadingScreen/Loading';
// import DeleteModal from '../ReUsedComponents/deleteModal/DeleteModal'

import { getAllContacts, createContact, updateContact, deleteContact } from '../../apis/ContactsAPI';
import { useDispatch } from 'react-redux';
import dateFormat from 'dateformat';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Contacts = () => {

    const dispatch = useDispatch()

    const [actionPending, setActionPending] = useState(false);

    const [createModalState, setCreateModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);

    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    
    const [contact, setContact] = useState({ name: '', phoneNumber: ''});
    const [formErrors, setFormErrors] = useState()
    const [allContacts, setAllContacts] = useState([]);

    useEffect(() => {
        getAllContacts(dispatch, pageNo, pageSize).then(resp => {
          if (resp?.status === 200) { setAllContacts(resp?.data); }
          else { toast.error("unable to fetch contacts!", { theme: "colored" }) }
        })
      
    }, [dispatch, pageSize, pageNo])


  const columns = [
      { field: 'name', headerName: 'Name', flex: 1, minWidth: 200, headerClassName: 'custom-table-header', sortable: false,
      renderCell: (param) => <span>{param.row.name? param.row.name : "--"}</span>},
      { field: 'phoneNumber', headerName: 'Phone Number', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false },
      { field: 'group', headerName: 'Group', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false,
      renderCell: (param) => <span>{param.row.group? param.row.group : "--"}</span>},
      { field: 'createdAt', headerName: 'Created At', flex: 1, minWidth: 180, headerClassName: 'custom-table-header', sortable: false,
      renderCell: (param) => <span>{dateFormat(param.row.createdAt, "dd/mm/yyyy HH:MM")}</span>},
      { field: 'updatedAt', headerName: 'Updated At', flex: 1, minWidth: 180, headerClassName: 'custom-table-header', sortable: false,
      renderCell: (param) => <span>{dateFormat(param.row.updatedAt, "dd/mm/yyyy HH:MM")}</span>},
      { field: 'actions', headerName: 'Actions', width: 150, headerClassName: 'custom-table-header', sortable: false,
          renderCell: (param) => (
              <div className='contacts-table-actions'>
                <i class="pi pi-pencil" id="edit-action-icon" onClick={() => { setContact({ id: param.row?.id, name: param?.row?.name, phoneNumber: param?.row?.phoneNumber}); setEditModalState(true) }}/>
                <i class="pi pi-trash" id="delete-action-icon" onClick={() => handleDeleteContact(param?.row?.id) } />
              </div>
          )
      },
  ];


  const handleOnChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value })
  }

  const validate = () => {
    let errors = {}
    if (!contact?.name) {
      errors['name'] = 'Name is required!'
    }
    if (!contact?.phoneNumber) {
      errors['number'] = 'Invalid phone number.Eg 254123456789!'
    }
    setFormErrors(errors)
    return errors
  };

  
  const handleCloseModal = () => {
    setContact({ name: '', phoneNumber: ''})
    setFormErrors([])
    document.getElementById("form-modal").reset();
    setCreateModalState(false)
    setEditModalState(false)
  }

  const handleCreateContact = () => {
    if (Object.keys(validate()).length === 0) {
      createContact(contact, dispatch).then(resp => {
        if (resp?.status === 200) {
          if (resp?.data.error === true) {
            toast.error(resp?.data.message, { theme: "colored" })
          } else {
            handleCloseModal(dispatch)
            toast.success("Created successful!", { theme: "colored" })
            window.location.reload()
          }
        }
        else {setActionPending(false);}
      })
    }
  }


  const handleUpdateContact = () => {
    if (Object.keys(validate()).length === 0) {
      updateContact(contact?.id, contact, dispatch).then(resp => {
        if (resp?.status === 200) {
            handleCloseModal(dispatch)
            toast.success("Update successful!", { theme: "colored" })
            window.location.reload()
        }
        else {
          toast.error("Error occured!", { theme: "colored" })
        }
      })
    }
  }

  const handleDeleteContact = (id) => {
    deleteContact(id, dispatch).then(resp => {
      if (resp?.status === 200) {
        handleCloseModal(dispatch)
        toast.success("Deleted successful!", { theme: "colored" })
        window.location.reload()
      }
      else {
        toast.error("Error occured!", { theme: "colored" })
      }
    })
    setContact({ name: '', phoneNumber: ''})
    // setDeleteAction(false)
  }

    return (
        <div class='table-page'>

            <div className='table-actions-sections'>
                <div className='table-page-title-section'>
                    <p className='table-page-title' style={{marginLeft: '11px'}}>Manage Contacts </p>
                </div>
                <div className='table-toolbar'>
                  <input type='text' className="search-table-input" placeholder='Search table...' />
                  <div>
                  <button type='button' className='create-btn' onClick={() => setCreateModalState(true)}>Add Contact</button>
                  <button type='button' className='import-btn'>Import Contacts</button>
                  <button type='button' className='export-btn'>Export Contacts</button>
                  </div>
                </div>
                
            </div>
            <div>

            {/* <div className='table-toolbar'>
                    
          </div> */}

        <div className='table-section'> 
           {allContacts?.length === 0? <div className='table-loading'><Loading /></div> : 
            <DataGrid rowHeight={40} rows={allContacts?.data} columns={columns} disableColumnMenu
                    className="datagrid-root"  getRowClassName="dashboard-transaction-rows"
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) =>setPageSize(newPageSize)}
                    onPageChange={(newPage) => setPageNo(newPage + 1)}
                    rowsPerPageOptions={[25, 50, 100]}
                    rowCount={allContacts?.totalElements}
                    paginationMode="server"
                    pagination
                    componentsProps={{panel: { className: "custom-colum-panel",}, }}
                    sx={{border: 'none',height: '500px'}} 
                    />}
            </div>
          
            </div>


  {/* /////////////////////CREATING CONTACT //////////// */}
  <div className={createModalState ? 'create-modal-active' : 'create-modal-inactive'} >
        <form id="form-modal" className='view-modal-content'>
          <p className='modal-title'> Create contact
            <i id='close-button' className="pi pi-times" onClick={() => handleCloseModal()} /></p>
          <div className="dialog-inputs">
            <Input label="Name " name='name' type='text' handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.name} />
            <Input label="Phone Number*" name='phoneNumber' type='number' errorMessage={formErrors?.number}
              handleChange={(e) => handleOnChange(e)} placeholder="Example: 254123456789" />
          </div>
          <div className='dialog-footer'>
            <SubmitButton pending={actionPending} handleOnSubmit={handleCreateContact} />
          </div>
        </form>
      </div>


      {/* /////////////////////EDITNG CONTACT //////////// */}
      <div className={editModalState ? 'view-modal-active' : 'view-modal-inactive'}>
        <form id="form-modal" className='view-modal-content'>
          <p className='modal-title'> Edit contact
            <i id='close-button' className="pi pi-times" onClick={() => handleCloseModal()} /></p>
          <div className="dialog-inputs">
            <Input label="Name" name='name' type='text' value={contact?.name} handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.name} />
            <Input label="Phone Number*" name='phoneNumber' type='number' errorMessage={formErrors?.number}
              value={contact?.phoneNumber} handleChange={(e) => handleOnChange(e)}/>
          </div>
          <div className='dialog-footer'>
            <SubmitButton pending={actionPending} handleOnSubmit={handleUpdateContact} />
          </div>
        </form>
      </div>



         {/* Delete confirm modal */}
         {/* <div className={deleteAllAction ? 'delete-modal-active' : 'delete-modal-inactive'}>
        <div className="delete-modal" >
          <img id="delete-img" src="https://100dayscss.com/codepen/alert.png" width="44" height="38" alt='warning' />
          <span className="action-title">Are you sure want to detele  contact(s)?</span>
          <p className='delete-message'>This contact(s) will be deleted immediately from your database.<br />You can't undo this action</p>

          <div className='a-buttons'>
            <button type='button' className="dismiss-button" onClick={() => setDeleteAllAction(false)}>Cancel</button>
            <button type='button' className="delete-button" onClick={() => handleDeleteContact(selectedDeleteContacts)}>
              {deleting? 'Deleting...' : 'Delete'}</button>
          </div>

        </div>
      </div> */}

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div>
    )
}

export default Contacts